<template>
    <div class="testBlockSample" v-loading="loading">
        <div class="base-box">
            <h1 class="title">
                编码规则设置
            </h1>
            <div class="choose-check">
                <div class="switch-item">
                    <el-switch
                        v-model="is_custom_code"
                        active-value="0"
                        inactive-value="1"
                        :disabled="disableForm"
                    >
                    </el-switch>
                    <span>默认</span>
                </div>
                <div class="switch-item">
                    <el-switch
                        v-model="is_custom_code"
                        active-value="1"
                        inactive-value="0"
                        :disabled="disableForm"
                    >
                    </el-switch>
                    <span>自定义</span>
                </div>
            </div>
            <div class="btns" v-if="!disableForm">
                <el-button size="mini" :disabled="disableBtn" @click="save('1')">
                    完成
                </el-button>
                <el-button
                    type="primary"
                    :disabled="disableBtn"
                    size="mini"
                    @click="save('0')"
                >
                    保存
                </el-button>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="default-content" v-show="is_custom_code==0">
                <h2 class="name">
                    编码格式展示
                </h2>
                <img src="../image/codeRuleSetbg.png">
            </div>
            <div class="custom-content" v-show="is_custom_code==1">
                <h2 class="name">
                    编码规则设置区
                </h2>
                <div class="panel">
                    <div class="tips">
                        <i class="iconfont iconwenxintishi"></i>提示：（Y）代表日期；（#）代表场站；
                    </div>
                    <div class="wrapper-list">
                        <div class="wrapper-item">
                            <label class="name">日期设置（Y）:</label>
                            <ul>
                                <li v-for="(item,index) in dateSetList" :key="'date'+index">
                                    <div class="radio-box">
                                        <el-radio
                                            v-model="activeDateSet.attribute_value"
                                            :label="item.code_name"
                                            @change="changeDateSet(item)"
                                            :disabled="disableForm"
                                        >
                                            {{ item.code_name }}
                                        </el-radio>
                                        <span class="example">
                                            例:{{ timeFormat(item.code_name) }}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper-item">
                            <label class="name">场站设置（#）:</label>
                            <ul>
                                <li v-for="(item,index) in stationSetList" :key="'station'+index">
                                    <div class="input-box">
                                        <span class="tag">{{ item.code_name }}</span>
                                        <input
                                            class="input"
                                            placeholder="请输入"
                                            v-model="item.attribute_value"
                                            :disabled="disableForm"
                                        >
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper-item">
                            <label class="name">试块组流水号:</label>
                            <ul>
                                <li v-for="(item,index) in testBlockGroupSetList" :key="'testBlockGroup'+index">
                                    <el-radio
                                        v-model="activeTestBlockGroupSet.attribute_value"
                                        :label="item.code"
                                        @change="changeTestBlockGroupSet(item)"
                                        :disabled="disableForm"
                                    >
                                        {{ item.code_name }}
                                    </el-radio>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper-item">
                            <label class="name">试块流水号:</label>
                            <ul>
                                <li v-for="(item,index) in testBlockSetList" :key="'testBlock'+index">
                                    <el-radio
                                        v-model="activeTestBlockSet.attribute_value"
                                        :label="item.code"
                                        @change="changeTestBlockSet(item)"
                                        :disabled="disableForm"
                                    >
                                        {{ item.code_name }}
                                    </el-radio>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper-item">
                            <label class="name">试块类型设置:</label>
                            <ul>
                                <li v-for="(item,index) in testBlockTypeSetList" :key="'testBlockType'+index">
                                    <div class="input-box">
                                        <span class="tag">{{ item.code_name }}</span>
                                        <input
                                            class="input input1"
                                            placeholder="请输入"
                                            v-model="item.attribute_value"
                                            @input="testBlockTypeChange(item)"
                                            :disabled="disableForm"
                                        >
                                    </div>
                                    <div class="code-label">
                                        {{ testBlockTypeSetLabel[item.code] }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="tip">
                            <i class="iconfont icontishi"></i>
                            试块类型设置中：请用字母代替（例：KP-Y-#- KP-20211216-J-0001-01;
                            KP-20211216-J-0001代表试块组编号；KP-202112116-J-0001-01代表试块编号）
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    name: 'code-rule-set',
    data() {
        return {
            loading: false,

            is_custom_code: '1',

            // 日期设置列表
            dateSetList: [],
            // 场站设置列表
            stationSetList: [],
            // 试块组流水号
            testBlockGroupSetList: [],
            // 试块流水号
            testBlockSetList: [],
            // 试块类型设置
            testBlockTypeSetList: [],

            testBlockTypeSetLabel: {},

            activeDateSet: {},
            activeTestBlockGroupSet: {},
            activeTestBlockSet: {},

            disableBtn: false,

            disableForm: false,

        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = true;
            // /testblock/load_code_settings
            const res = await this.$axios.$get('/interfaceApi/production/testblock/load_code_settings');
            // eslint-disable-next-line eqeqeq
            this.disableForm = res.is_custom_code_complete == 1;
            this.is_custom_code = res.is_custom_code;
            res.codeSetupList.forEach(item => {
                item.codeSetupExtendList.forEach(codeItem => {
                    const obj = Object.assign({}, codeItem);
                    obj.code_settings_type = item.code_settings_type;
                    obj.code_settings_type_name = item.code_settings_type_name;
                    if (item.code_settings_type === 2) {
                        this.dateSetList.push(obj);
                        if (obj.attribute_value) {
                            this.activeDateSet = obj;
                        }
                    } else if (item.code_settings_type === 3) {
                        this.stationSetList.push(obj);
                    } else if (item.code_settings_type === 4) {
                        this.testBlockGroupSetList.push(obj);
                        if (obj.attribute_value) {
                            this.activeTestBlockGroupSet = obj;
                        }
                    } else if (item.code_settings_type === 5) {
                        this.testBlockSetList.push(obj);
                        if (obj.attribute_value) {
                            this.activeTestBlockSet = obj;
                        }
                    } else if (item.code_settings_type === 6) {

                        this.testBlockTypeSetList.push(obj);
                    }
                });
            });
            this.generateLabelStr();
            this.loading = false;
        },
        // 保存  is_custom_code_complete 自定义编码是否完成 0：否 1：是
        async save(is_custom_code_complete) {
            const data = {
                is_custom_code: this.is_custom_code,
                is_custom_code_complete: is_custom_code_complete,
                codeSetupList: [
                    ...this.stationSetList,
                    ...this.testBlockTypeSetList,
                ],
            };
            // 自定义
            // eslint-disable-next-line eqeqeq
            if (this.is_custom_code == 1) {
                if (!this.activeDateSet.attribute_value) {
                    this.$message.warning('请选择日期设置');
                    return;
                }
                for (const station of this.stationSetList) {
                    if (!station.attribute_value) {
                        this.$message.warning('请填写场站设置');
                        return;
                    }
                }
                if (!this.activeTestBlockGroupSet.attribute_value) {
                    this.$message.warning('请选择试块组流水号');
                    return;
                }
                if (!this.activeTestBlockSet.attribute_value) {
                    this.$message.warning('请选择试块流水号');
                    return;
                }
                for (const testBlockType of this.testBlockTypeSetList) {
                    if (!testBlockType.attribute_value) {
                        this.$message.warning('请填写试块类型设置');
                        return;
                    }
                }
                data.codeSetupList = [
                    this.activeDateSet,
                    this.activeTestBlockGroupSet,
                    this.activeTestBlockSet,
                    ...this.stationSetList,
                    ...this.testBlockTypeSetList,
                ];
            }
            if (is_custom_code_complete === '1') {
                try {
                    await this.$confirm('完成后将无法修改，确认完成?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    });
                } catch (e) {
                    return;
                }

            }
            this.disableBtn = true;

            try {
                await this.$axios.$post('/interfaceApi/production/testblock/save_code_settings'
                    , data
                );
                this.$message.success('操作成功');
            } catch (e) {
                this.$message.error( e.ErrorCode.Message);
            }
            if (is_custom_code_complete === '1') {
                this.disableForm = true;
            }
            this.disableBtn = false;
        },
        // 日期设置变化
        changeDateSet(item) {
            item.attribute_value = item.code_name;
            this.activeDateSet = item;
            this.generateLabelStr();
        },
        // 试块组变化
        changeTestBlockGroupSet(item) {
            item.attribute_value = item.code;
            this.activeTestBlockGroupSet = item;
            this.generateLabelStr();
        },
        // 试块设置变化
        changeTestBlockSet(item) {
            item.attribute_value = item.code;
            this.activeTestBlockSet = item;
            this.generateLabelStr();
        },
        // 生成试块模板样例
        generateLabelStr() {
            this.testBlockTypeSetList.forEach(item => {
                this.testBlockTypeChange(item);
            });
        },
        // 试块类型设置输入变化
        testBlockTypeChange(item) {
            if ( item.attribute_value) {
                let labelStr = item.attribute_value;
                if (this.activeDateSet.attribute_value) {
                    const timeStr = util.currentTimeFormat(this.activeDateSet.attribute_value);
                    labelStr = labelStr.replaceAll(/Y/g, timeStr);
                }
                const station_label = this.stationSetList[0] ? this.stationSetList[0].attribute_value : '';
                if (station_label) {
                    labelStr = labelStr.replaceAll(/#/g, station_label);
                }
                if (this.activeTestBlockGroupSet.attribute_value) {
                    let groupStr = '-';
                    for (let i = 0; i < this.activeTestBlockGroupSet.attribute_value - 1; i++) {
                        groupStr += '0';
                    }
                    labelStr += groupStr + '1';
                }
                if (this.activeTestBlockSet.attribute_value) {
                    let str = '-';
                    for (let i = 0; i < this.activeTestBlockSet.attribute_value - 1; i++) {
                        str += '0';
                    }
                    labelStr += str + '1';
                }
                this.testBlockTypeSetLabel[item.code] = labelStr;
            } else {
                this.testBlockTypeSetLabel[item.code] = '';
            }

        },
        timeFormat(format) {
            return util.currentTimeFormat(format);
        },
    },
};
</script>

<style lang="stylus" scoped>
.testBlockSample
    width 100%
    height 100%
    padding 0.18rem 0.3rem
    .base-box
        position relative
        .title
            font-size 0.3rem
            color #333333
        .choose-check
            padding 10px 0
            display flex
            .switch-item
                display flex
                align-items center
                font-size 0.16rem
                color #666
                margin-right 0.2rem
                span
                    margin-left 0.06rem
        .btns
            position absolute
            top 0.2rem
            right 0.2rem
    .content-wrapper
        width 100%
        height calc(100% - 1.18rem)
        border-top 1px solid #F0F0F0
        .default-content
            width 80%
            img
                width 100%
                height auto;
        .name
            padding-top 0.2rem
            padding-bottom 0.1rem
            color #666666
            font-size 0.16rem
        .custom-content
            height 100%
            .panel
                width 100%;
                height calc(100% - 0.47rem)
                border 1px solid #F0F0F0
                overflow-y auto
                .tips
                    padding-left 0.2rem
                    display flex
                    align-items center
                    height: 0.4rem;
                    background: #FFF5D1;
                    color #FF8220
                    font-size 0.16rem
                    .iconfont
                        font-size 0.16rem
                        margin-right 0.1rem
                .wrapper-list
                    .wrapper-item
                        margin-top 0.06rem
                        display flex
                        align-items: baseline;
                        .name
                            width 1.4rem
                            text-align right
                            font-size 0.16rem
                            color #666
                            flex-shrink: 0;
                        .el-radio-group
                            .el-radio
                                display flex
                                margin-right 0
                            >>> .el-radio__label
                                padding-left 0.03rem
                        ul
                            width 100%
                            display flex
                            flex-wrap wrap
                            padding-left 0.2rem
                            li
                                display flex
                                align-items: center;
                                justify-content: center;
                                margin-right 0.2rem
                                margin-bottom 0.15rem
                                .radio-box
                                    display flex
                                    flex-direction column
                                    justify-content center
                                    align-items center
                                .example
                                    margin-top 0.05rem
                                    width fit-content
                                    padding 0.02rem 0.03rem
                                    background: #F5F5F5;
                                    border-radius: 0.02rem;
                                    border: 1px solid #D0D0D0;
                                    color #999999
                                    font-size 0.14rem
                                .input-box
                                    background: #FFFFFF;
                                    border-radius: 0.02rem;
                                    border: 1px solid #D0D0D0;
                                    display flex
                                    .tag
                                        display flex
                                        justify-content center
                                        align-items center
                                        width: 0.8rem;
                                        height: 0.38rem;
                                        background: #F5F5F5;
                                        border-right 1px solid #D0D0D0
                                        border-radius: 2px;
                                        font-size 0.14rem
                                        color #333333
                                        white-space nowrap
                                    .input
                                        border 0
                                        width 2.4rem
                                        padding-left 0.06rem
                                        color #333
                                        font-size 0.14rem
                                        &.input1
                                            width 1.8rem
                                .code-label
                                    margin-left 0.04rem
                                    padding 0 0.05rem
                                    display flex
                                    justify-content center
                                    align-items center
                                    min-width: 2.2rem;
                                    height: 0.4rem;
                                    background: #E8F4FF;
                                    border-radius: 0.02rem;
                                    border: 1px solid #9ACEFD;
                                    font-size 0.16rem
                                    color #5483A6
                    .tip
                        padding-left 1.6rem
                        font-size 0.14rem
                        color #FF8220
</style>
